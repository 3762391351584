 
<template>
  <div class="privacy-wrap">
    <div class="privacy-container">
      <h2>享樂地圖隱私權政策</h2>
      <div class="main-content">
        <p>您在使用群悅科技股份有限公司 CHYUN YUEH TECHNOLOGY CO., LTD.旗下平台「TWDD台灣代駕」網站、 應用程式及 Joymap 享樂地圖 (<a href="http://www.joymap.tw" target="_blank" class="link-style">http://www.joymap.tw</a> )所有相關子網站、 應用程式 (以下合稱「Joymap」) 時同意將個人資訊託付給我們。我們會致力於維護您對我們的信任。首先，讓我們協助您瞭解隱私權政策。</p>
        <p>本政策說明了Joymap 收集哪些資訊、如何使用和分享這些資訊，如果您有任何問題或請求，您可以平台上所提供之客服服務管道與我們聯繫。</p>

        <div class="list-content">
          <ol class="list-mandarin ">
            <li>
              <h6>資料收集與使用</h6>
              本政策說明 Joymap 如何收集和使用您的個人資訊以提供服務。本政策適用於我們在全球所有應用程式、網站或其他服務的使用者。
              本政策也適用於因為應用程式使用我們的服務，而向 Joymap 提供資訊，或 Joymap 因本身服務而收到相關資訊的對象 (例如與 Joymap 合作餐廳相關的個人聯絡資訊)。為符合本政策的說明要旨，以下將所有適用對象統稱為「使用者」或稱為「您」。
              <br>
              <br>
              在 Joymap 刊登廣告之廠商，或與 Joymap 連結之其他網站，也可能收集您的相關資料。對於您主動提供或因您主動點擊廣告而被 Joymap 以外之他人收集的個人資訊，這些廣告廠商或連結網站不適用我們的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策，且Joymap 不負任何連帶責任。
              <br>
              <br>
              本政策所述辦法須遵守我們營運地區的適用法律。僅在特定國家或地區的當地法律許可下，始可實施本政策所述辦法。若對我們於您所在國家或地區實施的辦法有疑問，請聯絡我們。
            </li>
            <li>
              <h6>收集的資訊</h6>
              Joymap 為使用者提供服務時，Joymap 或 Joymap 的服務供應商將會收集以下資訊：
              <ol class="list-decimal">
                <li>
                  基本資訊：
                  <ul class="list-circle">
                    <li>
                      使用者基本資料：我們會在您建立或更新 Joymap 帳戶時收集資訊。這可能包含您的姓名、電子郵件地址、電話號碼、登入名稱和密碼、地址、付款或銀行資訊 (包括相關付款驗證資訊)、國民身分證號碼 (包括若依法須提供駕照或護照資訊)、出生日期、性別、家庭、教育、職業、相片和簽名。同時也包含您為 Joymap 帳戶啟用的設定與偏好設定。
                    </li>
                    <li>
                      使用族群資料：我們會透過使用者問卷調查或意見回饋收集關於您的使用族群資訊。在部分國家/地區，我們也會從第三方處收集您的使用族群資訊。
                    </li>
                    <li>使用者內容：當您與 Joymapo 客服聯絡、給予其他使用者評分或好評、或以其他方式與 Joymap 聯絡時，我們會收集您提交的資訊。</li>
                  </ul>
                </li>
                <li>
                  使用我們服務時建立之資訊：
                  <ul class="list-circle">
                    <li>
                      位置資訊：我們可能會透過 GPS、IP 位址和 Wi-Fi 確定的精確或大致位置收集您的位置資訊。具體收集方式依您使用的 Joymap 服務、App 設定或裝置權限而異。使用者無須允許 Joymap 收集位置資訊，亦可使用 Joymap 服務。然而，這樣可能會影響Joymap 平台的可用功能。
                    </li>
                    <li>
                      交易資訊：當您使用我們服務時，我們會收集相關的交易詳細資訊，包括但不限於您接受之店家名稱、服務類型、消費資訊、提供服務的日期和時間、扣款金額以及付款方式等。另外，若有人使用您的優惠序號，我們也會將您的姓名與使用序號的人連結。
                    </li>
                    <li>
                      使用資訊：當您使用我們服務時，我們會收集相關的互動資訊。此類資訊包括存取日期、時間和次數、平台功能或檢視的頁面、平台當機情況和其他系統活動、瀏覽器類型、您與服務互動前使用的第三方網站或服務等。在某些情況下，我們會透過 Cookie、像素標記，以及其他用於建立和保存特殊識別碼的類似技術來收集此類資訊。
                    </li>
                    <li>
                      裝置資訊：我們會在您透過裝置使用服務時收集裝置資訊，包括硬體機型、裝置 IP 位址、作業系統和版本、軟體、檔案名稱和版本、偏好語言、唯一裝置識別碼、廣告識別碼、序號、裝置運行資訊和行動網路資訊。
                    </li>
                  </ul>
                </li>
                <li>
                  Joymap 可能結合運用收集自此來源的資訊與本身擁有的其他資訊，相關資訊與來源包括：
                  <ul class="list-circle">
                    <li>
                      使用者意見回饋，例如評分或評論。
                    </li>
                    <li>使用者因推薦方案而提供了您的資訊。</li>
                    <li>由使用者或他人提供，有關索賠或異議的資訊。</li>
                    <li>提供服務以讓您建立或存取 Joymap 帳戶的 Joymap 企業合作夥伴所提供之資訊。例如：付款服務供應商、社群媒體服務商，以及使用 Joymap API 或提供 API 給 Joymap 使用的 App 或網站 (例如在您透過 Google Maps 使用相關流程時)所提供之資訊。</li>
                    <li>可用的公開來源。</li>
                    <li>行銷服務供應商所提供之資訊。</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              <h6>我們如何使用您的資訊</h6>
              Joymap 將會蒐集、處理並使用您的個人資訊，以實現平台內容交流及其他產品與服務。
              <br>
              Joymap 將使用收集的資訊來提供個人化、保存及改善產品和服務，例如：
              <ol class="list-decimal">
                <li>建立與更新帳戶資訊、驗證身份、處理或促成各項服務的付款程序。</li>
                <li>提供、取得、供應或促成與我們服務相關的保險或貸款解決方案。</li>
                <li>啟用與其他對象分享資訊的功能，例如給予評分、推薦朋友使用 Joymap 或分享使用資訊。</li>
                <li>用於協助維護及改善 Joymap 服務和使用者的安全、保障和誠信，包含使用裝置、地點、基本資料、使用量和其他資訊來防範、偵測和打擊詐騙或不安全的活動。這包括在特定國家/地區處理此類資訊，藉以識別表現出詐騙或安全風險的行為或模式。此類資訊亦包括來自第三方的資訊。在特定情況下，上述事件可能導致風險時將以決策程序停用帳戶。</li>
                <li>透過使用者評分鼓勵受影響的當事人進行改善，並將其做為帳戶停用標準，停用評分低於所在地區可能規定之特定最低分數的使用者。系統可能透過自動決策程序計算評分和停用帳戶。</li>
                <li>Joymap 會在您聯絡客服團隊時，使用收集的資訊 (包括經通知並取得您同意後的客服通話記錄) 為您提供協助。</li>
                <li>我們可能會使用收集的資訊進行測試、研究、分析和產品開發，修訂演算法。上述做法可讓我們改善並強化服務的安全防護、開發新功能和產品，以及協助處理與服務相關的保險和財務解決方案，並改善我們的服務效率。</li>
                <li>Joymap 可能會使用收集的資訊與您聯絡，提供關於產品、服務、額外獎勵/優惠、研究、調查、新聞、更新和活動的資訊。Joymap 也可能使用這些資訊推廣和處理相關競賽和抽獎、提供任何相關獎項，並提供您有關 Joymap 與其業務合作夥伴服務的廣告和內容。您可能會收到一些根據 Joymap 使用者基本資料而傳送的上述通訊內容。</li>
                <li>為提供訂購、行銷、客戶管理或其他合於營業登記項目或章程所定業務需要之目的， Joymap 於營運期間及地區內，可能以電郵、傳真、電話、簡訊或其他通知公告方式利用您所提供之資料。利用對象包括 Joymap 與其合作夥伴。</li>
              </ol>
              若您希望 Joymap 停止上述相關活動，您可以透過 Joymap 平台所提供之客服管道通知我們。

            </li>
            <li>
              <h6>Cookies及第三方技術</h6>
              Joymap 與其合作夥伴為了本政策中所述的目的，在我們的 App、網站、電子郵件及線上廣告上使用 cookie 及其他識別技術。Cookie 是由網站、應用程式、線上媒體及廣告在您的瀏覽器或裝置上儲存的小型文字檔。Joymap 將 Cookie 和類似技術應用於如下目的：
              <ul class="list-circle">
                <li>
                  驗證使用者
                </li>
                <li>記住使用者偏好和設定</li>
                <li>確定內容的受歡迎度</li>
                <li>推送廣告活動並衡量其效果</li>
                <li>分析網站流量和趨勢，並大致瞭解 Joymap 服務使用者的線上行為和興趣。Joymap 可能也會允許其他服務實體提供收視率調查與分析服務、透過網際網路代表我們供應廣告以及追蹤與報告廣告成效。這些實體可能會使用 Cookie、網站信標、軟體開發套件 (SDK) 和其他技術，在您參訪我們網站、使用服務及參訪其他線上網站和服務時識別您的裝置。</li>
              </ul>
            </li>
            <li>
              <h6>資訊分享及揭露</h6>
              Joymap絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，除非事先取得您的同意、有法律依據和合約義務、或有以下情形，不在此限：
              <ol class="list-decimal">
                <li>
                  Joymap 為提供、維護、管理和改進產品、服務和功能需要我們與其他使用者或合作夥伴 (或依您要求) 分享您的資訊時，前述資料亦可能使用於與使用者或合作夥伴分享、協同分析、使用作為廣告投放/應用 及 改善使用者體驗作業等用途。
                </li>
                <li>我們也可能基於法律因素或在出現索賠要求或糾紛時，與子公司、附屬公司和商業合作夥伴分享您的資訊，或於司法或政府機關依法要求 Joymap 提供特定個人資訊時， 依司法單位合法正式的程序做必要的配合。為了保護使用者個人隱私及遵守個人資料保護法之規定，我們無法為您查詢其他使用者的帳號資料。若您有相關法律上問題需查閱他人資料時，請向有權處理之政府單位提出告訴，我們將依法配合政府單位調查並提供政府單位依法要求之相關資料，以協助調查。</li>
                <li>當您使用 Joymap 服務的行為違反相關會員服務條款，或者可能損害 Joymap 、使用者或相關第三人之權益時，若 Joymap 有理由相信揭露此資料係為辨識、連絡或對該人採取法律行動所必要者， Joymap 得揭露使用者之個人資料。</li>
                <li>在某些特殊情形，我們會將您的個人資料傳送到國外進行處理。這些國家或地區對於個人資料保護之規定可能與台灣不同，但我們會確保我們的關係企業或我們的海外服務供應商在處理個人資料時，應該受到與在臺灣境內處理時一樣的保護。</li>
              </ol>
            </li>
            <li>
              <h6>資訊保留及刪除</h6>
              為順利提供服務， Joymap 會要求使用者提供基本資料，並會在您使用 Joymap 帳號期間保留這些資訊。惟 Joymap 仍會依適用法規、稅務、保險或其他要求，保留交易、地點、使用情況和其他資訊 3 年。3年期滿後，Joymap 會依適用法規刪除上述資訊，或對上述資訊進行匿名處理。
              <br><br>
              當 Joymap 不再需要為提供服務、啟用客服支援、改善使用體驗或其他營運目的使用上述資訊時，即會採取相應步驟，確保僅在遵循上述要求，或為保障安全與防範/偵測詐騙的情況下，才可存取或使用上述資訊。
              <br><br>
              針對 Joymap 所蒐集之您的個人資料，您可以查詢或請求閱覽，請求提供複製本，請求進行補充或更正，請求停止蒐集、處理或利用，以及請求刪除等。如果您請求 Joymap 刪除帳戶，在特定情況下， Joymap 可能無法刪除您的帳戶與個人資料，例如您的帳戶尚有未結清的點數，或未解決的索賠或糾紛。待解決造成帳戶無法刪除的問題後， Joymap 即會依上述方式刪除您的帳戶與個人資料。
              <br><br>
              此外，如果您拒絕提供或者要求 Joymap 刪除個人資料，將可能會導致 Joymap 無法繼續提供任何服務給您。
              <br><br>
              如有合法商業利益上的需求， Joymap 也會保留部分資訊，例如用於防範詐騙，以及為使用者強化安全保障。舉例而言，若 Joymap 因危險行為或安全事件而關閉某個使用者帳戶，則 Joymap 可能保留該帳戶的特定資訊，以防止該使用者將來啟用新的 Joymap 帳戶。
            </li>
            <li>
              <h6> 隱私權保護政策之修正</h6>
              Joymap 隱私權政策將因應需求隨時進行修正，修正後的條款將刊登於 Joymap 平台上，使用者若繼續使用 Joymap 及相關服務則視同接受隱私權保護。
            </li>
          </ol>

        </div>

      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "PrivacyPolicy",
  components: {
    Footer,
  },

  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {},
};
</script>

<style lang="scss">
.list-content {
  margin-top: 1.5rem;
  h6 {
    font-weight: bold;
    margin-top: 1rem;
  }
}
.list-mandarin {
  list-style-type: cjk-ideographic;
  padding: 0 0 0 2rem;
}

@media screen and (min-width: 768px) {
  .list-mandarin {
    list-style-type: cjk-ideographic;
    padding: 0 0 0 3rem;
  }
}

.list-decimal {
  list-style: decimal;
  padding: 0 0 0 1rem;
}

@media screen and (min-width: 768px) {
  .list-decimal {
    padding: 0 0 0 3rem;
  }
}

.list-circle {
  list-style: disc;
  padding: 0 0 0 1rem;
}
@media screen and (min-width: 768px) {
  .list-circle {
    list-style: disc;
    padding: 0 0 0 3rem;
  }
}

ol > li::marker {
  font-weight: bold;
}

.privacy-container {
  min-height: calc(100vh - 232px);
  margin: 0 auto;
  max-width: 1200px;
  line-height: 2rem;
  padding: 0 2rem;
  .main-content {
    padding: 0 0 3rem 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0.2rem;
    padding: 2rem 0;
    text-align: center;
  }
}

@media screen and (min-width: 768px) {
  .privacy-container {
    padding: 0 3rem;
    h2 {
      padding: 3.75rem 0;
    }
  }
}

.link-style {
  color: #fa6c3e;
  box-shadow: 0 1px;
  padding-bottom: 1px;
}
</style>

