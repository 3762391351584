<template>
  <footer class="bg-fill flex flex-col justify-center items-center p-10">
    <!-- <img src="@/assets/image/mainPage/joymap-logo-02.webp" alt="logo" class="mb-5"> -->
    <img src="@/assets/image/mainPage/joymap-logo-02@3x.png" alt="logo" class="mb-5 footerLogo">

    <ul class="flex justify-center text-main pb-5 font-medium text-xs md:text-base">
      <li class="px-3"><a href="">關於我們</a></li>
      <li class="px-3"><a href="/privacy-policy" target="_black" >隱私政策</a></li>
      <li class="px-3"><a href="/terms-of-service" target="_black" >使用條款</a></li>
    </ul>
    <p class="text-xs text-grayColor-darkest  pb-5">
      Copyright 2021 Joymap Ltd. All rights reserved.
    </p>
    <ul class="flex text-xs text-grayColor-darkest ">
      <li class="px-3">Powered by</li>
      <li class="px-3 text-main"><a href="">Joymap</a></li>
      <li class="px-3">Version 1.0.0</li>
    </ul>
  </footer>
</template>
<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footerLogo {
  width: 140px;
}
</style>
